import { LINKS, DOCS_LINK, ASHBY_JOBS_URL } from 'routes/constants'

export type HeaderNavigationDataType = {
  name: string
  href?: string
  isBlank?: boolean // for external links
  type?: string // for adjusting submenu position on desktop
  columns?: HeaderNavigationSubmenuDataType[]
}

export type HeaderNavigationSubmenuDataType = {
  caption?: string
  links: HeaderNavigationSubmenuLinkDataType[]
}

type HeaderNavigationSubmenuLinkDataType = {
  title: string
  href: string
  isBlank?: boolean // for external links
}

export const headerNavigationData: HeaderNavigationDataType[] = [
  {
    name: 'Product',
    type: '__left',
    columns: [
      {
        caption: 'Overview',
        links: [
          {
            title: 'How We Are Different',
            href: LINKS.HOW_WE_ARE_DIFFERENT,
          },
          {
            title: 'How We Use AI',
            href: LINKS.HOW_WE_USE_AI,
          },
        ],
      },
      {
        caption: 'Features',
        links: [
          {
            title: 'Universal Integrations',
            href: LINKS.UNIVERSAL_INTEGRATIONS,
          },
          {
            title: 'Integration UX',
            href: LINKS.INTEGRATION_UX,
          },
          {
            title: 'Connector Builder',
            href: LINKS.CONNECTOR_BUILDER,
          },
        ],
      },
    ],
  },
  {
    name: 'Solutions',
    type: '__left',
    columns: [
      {
        caption: 'By Business Case',
        links: [
          {
            title: 'One-click integrations',
            href: LINKS.SOLUTIONS.ONE_CLICK_INTEGRATIONS,
          },
          {
            title: 'On-demand integrations',
            href: LINKS.SOLUTIONS.ON_DEMAND_INTEGRATIONS,
          },
          {
            title: 'Migration from other apps',
            href: LINKS.SOLUTIONS.MIGRATION_FROM_OTHER_APPS,
          },
        ],
      },
      {
        caption: 'By Integration Type',
        links: [
          {
            title: 'Real-time data import',
            href: LINKS.SOLUTIONS.REALTIME_DATA_SYNC,
          },
          {
            title: 'Import specific data from external apps',
            href: LINKS.SOLUTIONS.IMPORT_FROM_EXTERNAL_APP,
          },
          {
            title: 'Push data to external apps',
            href: LINKS.SOLUTIONS.PUSH_DATA_TO_EXTERNAL_APPS,
          },
          {
            title: 'In-app workflow builder',
            href: LINKS.SOLUTIONS.IN_APP_WORKFLOW_BUILDER,
          },
        ],
      },
      {
        caption: 'Advanced Use Cases',
        links: [
          {
            title: 'Data integration platforms',
            href: LINKS.SOLUTIONS.DATA_INTEGRATION_PLATFORMS,
          },
          {
            title: 'Workflow automation tools',
            href: LINKS.SOLUTIONS.WORKFLOW_AUTOMATION_TOOLS,
          },
          {
            title: 'AI Agents',
            href: LINKS.SOLUTIONS.AI_AGENTS,
          },
        ],
      },
    ],
  },
  {
    name: 'Integrations',
    type: '__center',
    columns: [
      {
        links: [
          {
            title: 'Apps',
            href: LINKS.INTEGRATIONS,
          },
          {
            title: 'Scenarios',
            href: LINKS.SCENARIOS,
          },
        ],
      },
    ],
  },
  {
    name: 'Pricing',
    href: LINKS.PRICING,
  },
  {
    name: 'Docs',
    href: DOCS_LINK,
    isBlank: true,
  },
  {
    name: 'Company',
    type: '__center',
    columns: [
      {
        links: [
          {
            title: 'About Us',
            href: LINKS.ABOUT_US,
          },
          {
            title: 'Blog',
            href: LINKS.ARTICLES,
          },
          {
            title: 'Careers',
            href: ASHBY_JOBS_URL,
            isBlank: true,
          },
        ],
      },
    ],
  },
]
